




















import { Component, Prop, Ref } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
@Component({})
@Translatable("form_components")
export default class djlFileUpload extends mixins<TranslatableComponent>() {
  @Prop()
  public name!: string;

  @Prop({ default: "Selecteer bestand(en)" })
  public label?: string;

  @Prop({ default: false })
  public required?: boolean;

  @Prop()
  public accept?: string;

  @Ref() readonly files!: HTMLInputElement;

  private isValid = false;
  private validityChecked = false; //becomes true when trying to submit, false again when value of multiselect changed

  filesSelected(): void {
    this.validityChecked = false;
    this.$emit("input", this.files.files);
  }

  resetValue() {
    this.files.value = "";
    this.filesSelected();
  }

  validate(): boolean {
    this.validityChecked = true;
    const fileList = (this.$refs.files as HTMLInputElement).files as FileList;

    if (fileList.length == 0) {
      this.isValid = !this.required;
      return this.isValid;
    }
    const invalidEndingCharacters: string[][] = [
      [" ", "validation_filename_space_before_extension"],
      [".", "validation_filename_dot_before_extension"],
    ];
    for (let file of fileList) {
      if (!this.filenameHasValidExtension(file.name)) {
        this.$snotify.error(
          `${this.translated_component_value("validation_filename_extension")}`
        );
        return false;
      }

      for (let [character, message] of invalidEndingCharacters) {
        if (this.filenameEndsWithInvalidCharacter(file.name, character)) {
          this.$snotify.error(`${this.translated_component_value(message)}`);
          this.isValid = false;
          return this.isValid;
        }
      }
    }
    this.isValid = true;
    return this.isValid;
  }

  get computedName(): string {
    return this.name.toLowerCase().split(" ").join("-");
  }

  filenameHasValidExtension(filename: string) {
    const extensionSeparatorIndex = filename.lastIndexOf(".");
    if (
      extensionSeparatorIndex < 1 ||
      extensionSeparatorIndex >= filename.length - 1
    ) {
      return false;
    }
    const extension = filename.substr(extensionSeparatorIndex + 1);
    return /[a-zA-Z0-9]+$/.test(extension);
  }

  filenameEndsWithInvalidCharacter(filename: string, character: string) {
    const extensionSeparator = ".";
    const hasExtension = filename.indexOf(extensionSeparator);
    if (hasExtension < 0) {
      return false;
    }
    const nameWithoutExtension = filename.substr(
      0,
      filename.lastIndexOf(extensionSeparator)
    );
    return nameWithoutExtension.endsWith(character);
  }
}
